import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'
import RenderIf from '../../components/RenderIf'
import Link from '../../components/Link'
// @ts-ignore
import * as styles from './styles.module.css'

interface TeamMemberProps {
  data?: {
    nodeTeamMember: any
    allNodeBlog: any
    allNodeCaseStudy: any
  }
}

const TeamMember: React.FC<TeamMemberProps> = ({ data }: TeamMemberProps) => {
  const node = data?.nodeTeamMember
  const blogs = data?.allNodeBlog?.nodes ?? []
  const caseStudies = data?.allNodeCaseStudy?.nodes ?? []
  const content = [].concat(blogs).concat(caseStudies)

  return (
    <Layout title={node.title}>
      <div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 sm:mb-12 lg:px-8">
          <div>
            <div className="p-6 flex sm:flex-row flex-col items-center">
              <div className="flex-shrink-0 sm:mr-8 mb-8 sm:mb-0">
                <span className="sr-only">{node?.title}</span>
                <GatsbyImage className={`h-30 w-30 rounded-full ${styles.imgContainer}`} imgClassName={`${styles.imgCard}`} image={node?.relationships?.field_avatar?.gatsbyImageData} alt="" />
              </div>
              <div className="sm:ml-3">
                <div className="font-medium text-gray-900">
                  <h1 className="font-sans text-md md:text-lg">
                    <span className="mr-3">👋</span>
                    <span className="text-white">{node.title}</span>
                  </h1>
                  <div className="font-sans text-md md:text-lg text-gray-500">
                    <a href={`mailto:${node?.field_email}`}>
                      <span className="mr-3">📫</span>
                      <span className="text-gray-300">{node?.field_email}</span>
                    </a>
                  </div>
                  <div className="font-sans text-md md:text-lg text-gray-300">
                    <span className="mr-3">🗺️</span>
                    <span>Located in </span>
                    <span>{node?.field_location}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RenderIf condition={content.length > 0}>
            <div className="mt-6 pt-10">
              <div className="border-b-2 w-full">
                <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl mb-6">My latest posts</h2>
              </div>
              <div className="pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-12 lg:gap-y-12">
                {content.map((blog: any) => (
                  <div key={blog?.id}>
                    <p className="text-sm text-gray-300">
                      <time dateTime={blog?.created}>{blog?.formattedDate}</time>
                    </p>
                    <Link to={blog?.path?.alias} className="mt-2 block">
                      <h3 className="font-serif text-xl font-semibold text-white">{blog?.title}</h3>
                      <p className="mt-3 text-base text-gray-300">{blog?.body?.summary}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </RenderIf>
        </div>
      </div>
    </Layout>
  )
}

export const TeamMemberQuery = graphql`
  query TeamMemberQuery($drupal_internal__nid: Int!) {
    nodeTeamMember(drupal_internal__nid: { eq: $drupal_internal__nid }) {
      title
      field_email
      field_location
      relationships {
        field_avatar {
          gatsbyImageData(width: 128, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }

    allNodeBlog(filter: { relationships: { field_author: { drupal_internal__nid: { eq: $drupal_internal__nid } } } }, sort: { fields: [created], order: DESC }) {
      nodes {
        id
        title
        body {
          summary
        }
        formattedDate: created(formatString: "Do [of] MMMM, YYYY")
        created
        path {
          alias
        }
      }
    }

    allNodeCaseStudy(filter: { relationships: { field_author: { drupal_internal__nid: { eq: $drupal_internal__nid } } } }, sort: { fields: [created], order: DESC }) {
      nodes {
        id
        title
        body {
          summary
        }
        formattedDate: created(formatString: "Do [of] MMMM, YYYY")
        created
        path {
          alias
        }
      }
    }
  }
`

export default TeamMember
